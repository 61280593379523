/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .puppy_profile_container {
    padding: 0;
    width: calc(100vw + 5px);
    background-color: var(--bg-light);
  }
  .puppy_profile_container .title {
    color: var(--cedar-dark);
    text-align: center;
    font-size: 36px;
    padding: 2% 0;
    font-weight: 250;
    margin: 0rem auto;
    max-width: 80vw;
  }
  .puppy_profile_container .icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .puppy_profile_container .icons .btn {
    display: flex;
    justify-content: center;
    width: 65px;
    margin-bottom: 2rem;
  }
  .puppy_profile_container .icons .btn.chevron-left {
    pointer-events: none;
  }
  .puppy_profile_container .icons .btn.chevron-left.show {
    pointer-events: all;
  }
  .puppy_profile_container .icons .btn.chevron-right {
    pointer-events: none;
  }
  .puppy_profile_container .icons .btn.chevron-right.show {
    pointer-events: all;
  }

  .puppy_profile_container .icons .chevron-left,
  .puppy_profile_container .icons .chevron-right {
    visibility: hidden;
  }
  .puppy_profile_container .icons .chevron-left.show,
  .puppy_profile_container .icons .border-all,
  .puppy_profile_container .icons .chevron-right.show {
    height: 100%;
    width: 60px;
    fill: var(--almond-dark);
    visibility: visible;
  }

  .puppy_profile_container .profile_container {
    width: 100%;
    display: grid;
  }
  .puppy_profile_container .profile {
    background-position: 90% 45%;
    width: 90%;
    height: 90vw;
    filter: opacity(0.8);
    background-size: cover;
    margin: 0 auto;
  }
  .profile_container {
    margin: 0 auto;
    max-width: 320px;
  }
  .profile_container .title.available_date {
    font-size: 28px;
    padding-bottom: 10px;
    display: flex;
  }
  .profile_container .title.available {
    font-size: 28px;
    color: var(--sea-green-dark);
    background-color: transparent;
    border-bottom: none;
  }
  .profile_container .statistics,
  .profile_container .body,
  .profile_container .program_message,
  .profile_container .delivery_message,
  .profile_container .price {
    color: var(--text-color-light) !important;
    font-size: 18px;
    padding-bottom: 15%;
    display: flex;
    margin: 0 5%;
  }
  .profile_container .program_message {
    display: inline-block;
    color: var(--text-color-light) !important;
  }
  .profile_container .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile_container .price .available {
    color: var(--sea-green-dark);
    font-size: 24px;
    font-weight: 250;
    margin: 10% auto 0 auto;
  }
  .profile_container .price .sold {
    color: var(--cedar-dark);
    font-size: 24px;
    font-weight: 500;
    margin: 10% auto 0 auto;
    text-decoration: line-through;
  }
  .profile_container .out_of_state_message {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    font-style: italic;
    margin: 10% auto 10% auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .puppy_profile_container {
    padding: 0;
    width: calc(100vw + 3px);
    background-color: var(--bg-light);
  }
  .puppy_profile_container .title {
    color: var(--cedar-dark);
    text-align: center;
    font-size: 36px;
    padding: 2% 0;
    font-weight: 250;
    margin: 0rem auto;
    max-width: 80vw;
  }
  .puppy_profile_container .icons {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .puppy_profile_container .icons .btn,
  .puppy_profile_container .icons .btn.chevron-left,
  .puppy_profile_container .icons .btn.chevron-right {
    display: flex;
    justify-content: center;
    width: 75px;
  }
  .puppy_profile_container .icons .btn.chevron-left {
    pointer-events: none;
  }
  .puppy_profile_container .icons .btn.chevron-left.show {
    pointer-events: all;
  }
  .puppy_profile_container .icons .btn.chevron-right {
    pointer-events: none;
  }
  .puppy_profile_container .icons .btn.chevron-right.show {
    pointer-events: all;
  }

  .puppy_profile_container .icons .chevron-left,
  .puppy_profile_container .icons .chevron-right {
    visibility: hidden;
  }
  .puppy_profile_container .icons .chevron-left.show,
  .puppy_profile_container .icons .border-all,
  .puppy_profile_container .icons .chevron-right.show {
    height: 100%;
    fill: var(--almond-dark);
    visibility: visible;
  }

  .puppy_profile_container .profile_container {
    width: 100%;
    display: grid;
  }
  .puppy_profile_container .profile {
    background-position: 90% 45%;
    width: 80vw;
    height: 80vw;
    filter: opacity(0.8);
    background-size: cover;
    max-width: 800px;
    max-height: 800px;
    margin: 0 auto;
  }
  .profile_container {
    margin: 0 auto;
    max-width: 85vw;
  }
  .profile_container .title.available_date {
    font-size: 28px;
    padding-bottom: 10%;
    display: flex;
  }
  .profile_container .title.available {
    font-size: 28px;
    color: var(--sea-green-dark);
    background-color: transparent;
    border-bottom: none;
  }
  .profile_container .statistics,
  .profile_container .body,
  .profile_container .program_message,
  .profile_container .delivery_message,
  .profile_container .price {
    color: var(--text-color-light) !important;
    font-size: 18px;
    padding-bottom: 10%;
    display: flex;
    margin: 0 5%;
  }
  .profile_container .program_message {
    display: inline-block;
    color: var(--text-color-light) !important;
  }
  .profile_container .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile_container .price .available {
    color: var(--sea-green-dark);
    font-size: 24px;
    font-weight: 250;
    margin: 10% auto 0 auto;
  }
  .profile_container .price .sold {
    color: var(--cedar-dark);
    font-size: 24px;
    font-weight: 500;
    margin: 10% auto 0 auto;
  }
  .profile_container .out_of_state_message {
    font-size: 18px;
    text-align: center;
    font-weight: 300;
    font-style: italic;
    margin: 1% auto 10% auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
