/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* TrainingAll */
  .trainingAll.common_image {
    /* background-image: url("../StockPhotos/group_down.jpeg"); */
    background-position: 40% 50%;
  }
  /* Private Lessons */
  .privateLessons.common_image {
    /* background-image: url("../StockPhotos/out_to_lunch.jpg"); */
    background-position: 30% 50%;
    background-size: 200%;
  }
  /* Private Lessons - Programs - Simply Manners */
  .show + .program_simply_manners.common_accordion_image {
    /* background-image: url("../StockPhotos/program_simply_manners.png"); */
    background-position: center;
  }
  /* Private Lessons - Programs - Simply Manners Plus Leash */
  .show + .program_simply_manners_plus_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/program_simply_manners_plus_leash.png"); */
    background-position: center;
  }
  /* Private Lessons - Programs - Ultimate Family Dog */
  .show + .program_ultimate_family_dog.common_accordion_image {
    /* background-image: url("../StockPhotos/program_ultimate_family_dog.png"); */
    background-position: center;
    background-size: 200%;
  }
  /* Private Lessons - Programs - Fiesty Fido */
  .show + .program_fiesty_fido.common_accordion_image {
    /* background-image: url("../StockPhotos/program_fiesty_fido.png"); */
    background-position: 90% 50%;
  }
  /* Private Lessons - Programs - Unlimited */
  .show + .program_unlimited.common_accordion_image {
    /* background-image: url("../StockPhotos/program_unlimited.png"); */
    background-position: center;
    background-size: contain;
  }
  /* Board And Train */
  .boardAndTrain.common_image {
    /* background-image: url("../StockPhotos/down_with_sealy.jpg"); */
    background-position: 40% 15%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.common_image {
    /* background-image: url("../StockPhotos/after_lake.jpeg"); */
    background-position: 30%;
  }
  /* Day Training */
  .dayTraining.common_image {
    /* background-image: url("../StockPhotos/open_garage_door.jpg"); */
    background-size: 150%;
    background-position: 40% 50%;
  }
  /* Day Training - Programs - Simply Manners */
  .show + .program_dt_simply_manners.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_simply_manners.png"); */
    background-position: center;
  }
  /* Day Training - Programs - Simply Manners Plus Leash */
  .show + .program_dt_simply_manners_plus_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_simply_manners_plus_leash.png"); */
    background-position: center;
  }
  /* Day Training - Programs - Ultimate Family Dog */
  .show + .program_dt_ultimate_family_dog.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_ultimate_family_dog.png"); */
    background-position: center;
    background-size: 250%;
  }
  /* Day Training - Programs - Fiesty Fido */
  .show + .program_dt_fiesty_fido.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_fiesty_fido.png"); */
    background-position: 60% 50%;
  }
  /* BT Adult*/
  .btAdult.common_image {
    /* background-image: url("../StockPhotos/eye_contact_2.jpeg"); */
    background-position: 0% 40%;
  }
  /* BT Puppy */
  .btPuppy.common_image {
    /* background-image: url("../StockPhotos/rowan_sleeping_on_the_porch.jpg"); */
    background-position: 0% 0%;
  }
  /* BT Puppy - Programs - Puppy Primer */
  .show + .program_bt_puppy_primer.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_puppy_primer.jpg"); */
    background-position: center;
  }
  /* BT Puppy - Programs - Polite Puppy */
  .show + .program_bt_polite_puppy.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_polite_puppy.jpeg"); */
    background-position: center;
  }
  /* BT Puppy - Programs - Perfect Puppy */
  .show + .program_bt_perfect_puppy.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_perfect_puppy.jpg"); */
    background-position: 70% 10%;
    background-size: 250%;
  }
  /* BT Puppy - Programs - Off Leash */
  .show + .program_bt_puppy_off_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/btpuppy.jpg"); */
    background-position: center;
    filter: brightness(120%);
  }
  /* Accordion */
  .training_accordion {
    margin: 1rem 32px !important;
  }
  .training_accordion .accordion-button {
    background-color: var(--cedar-dark);
    color: white;
  }
  .training_accordion_body {
    display: flex;
    flex-direction: column;
  }
  .training_accordion_button {
    margin: 10px auto;
  }
  /* Contact Form */
  .training_form_col {
    background-color: var(--cedar-primary);
    width: 100vw;
    padding-bottom: 6rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
  }
  .training_contact_us_form > p {
    text-transform: uppercase;
  }
  .training_contact_us_form > div {
    margin: 0 auto;
    width: 80%;
  }
  .training_contact_section_button {
    font-size: 22px;
    width: 45%;
    margin-left: 22%;
    margin-top: 2rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* TrainingAll */
  .trainingAll.common_image {
    /* background-image: url("../StockPhotos/group_down.jpeg"); */
    background-position: 40% 50%;
  }
  /* Private Lessons */
  .privateLessons.common_image {
    /* background-image: url("../StockPhotos/out_to_lunch.jpg"); */
    background-position: center;
  }
  /* Private Lessons - Programs - Simply Manners */
  .show + .program_simply_manners.common_accordion_image {
    /* background-image: url("../StockPhotos/program_simply_manners.png"); */
    background-position: center;
  }
  /* Private Lessons - Programs - Simply Manners Plus Leash */
  .show + .program_simply_manners_plus_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/program_simply_manners_plus_leash.png"); */
    background-position: center;
  }
  /* Private Lessons - Programs - Ultimate Family Dog */
  .show + .program_ultimate_family_dog.common_accordion_image {
    /* background-image: url("../StockPhotos/program_ultimate_family_dog.png"); */
    background-position: 50% 50%;
    background-size: contain;
  }
  /* Private Lessons - Programs - Fiesty Fido */
  .show + .program_fiesty_fido.common_accordion_image {
    /* background-image: url("../StockPhotos/program_fiesty_fido.png"); */
    background-position: center;
    background-size: contain;
  }
  /* Private Lessons - Programs - Unlimited */
  .show + .program_unlimited.common_accordion_image {
    /* background-image: url("../StockPhotos/program_unlimited.png"); */
    background-position: 50% 50%;
    background-size: contain;
  }
  /* Board And Train */
  .boardAndTrain.common_image {
    /* background-image: url("../StockPhotos/down_with_sealy.jpg"); */
    background-position: 25% 60%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.common_image {
    /* background-image: url("../StockPhotos/after_lake.jpeg"); */
    background-position: top;
  }
  /* Day Training */
  .dayTraining.common_image {
    /* background-image: url("../StockPhotos/open_garage_door.jpg"); */
    background-position: 0% 40%;
    background-size: 125%;
  }
  /* Day Training - Programs - Simply Manners */
  .show + .program_dt_simply_manners.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_simply_manners.png"); */
    background-position: center;
    background-size: contain;
  }
  /* Day Training - Programs - Simply Manners Plus Leash */
  .show + .program_dt_simply_manners_plus_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_simply_manners_plus_leash.png"); */
    background-position: center;
    background-size: 40%;
  }
  /* Day Training - Programs - Ultimate Family Dog */
  .show + .program_dt_ultimate_family_dog.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_ultimate_family_dog.png"); */
    background-position: center;
  }
  /* Day Training - Programs - Fiesty Fido */
  .show + .program_dt_fiesty_fido.common_accordion_image {
    /* background-image: url("../StockPhotos/program_dt_fiesty_fido.png"); */
    background-position: center;
  }
  /* BT Adult */
  .btAdult.common_image {
    /* background-image: url("../StockPhotos/eye_contact_2.jpeg"); */
    background-position: 0% 40%;
  }
  /* BT Puppy */
  .btPuppy.common_image {
    /* background-image: url("../StockPhotos/rowan_sleeping_on_the_porch.jpg"); */
    background-position: 0% 35%;
  }
  /* BT Puppy - Programs - Puppy Primer */
  .show + .program_bt_puppy_primer.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_puppy_primer.jpg"); */
    background-position: center 20%;
  }
  /* BT Puppy - Programs - Polite Puppy */
  .show + .program_bt_polite_puppy.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_polite_puppy.jpeg"); */
    background-position: center 60%;
  }
  /* BT Puppy - Programs - Perfect Puppy */
  .show + .program_bt_perfect_puppy.common_accordion_image {
    /* background-image: url("../StockPhotos/program_bt_perfect_puppy.jpg"); */
    background-position: center 0%;
  }
  /* BT Puppy - Programs - Off Leash */
  .show + .program_bt_puppy_off_leash.common_accordion_image {
    /* background-image: url("../StockPhotos/btpuppy.jpg"); */
    background-position: 50% 2rem;
    filter: brightness(120%);
  }

  /* Accordion */
  .training_accordion {
    margin: 0.5rem 32px !important;
  }
  .training_accordion .accordion-button {
    background-color: var(--cedar-dark);
    color: white;
  }
  .training_accordion_body {
    display: flex;
    flex-direction: column;
  }
  .training_accordion_button {
    margin: 10px auto;
  }
  /* Contact Form */
  .training_form_col {
    background-color: var(--cedar-primary);
    width: 100vw;
    padding-bottom: 6rem;
    margin-top: 4rem;
    margin-bottom: -10rem;
  }
  .training_contact_us_form > p {
    text-transform: uppercase;
  }
  .training_contact_us_form > div {
    margin: 0 auto;
    width: 70%;
  }
  .training_contact_us_form > div > .form-label {
    font-size: 16px;
    font-weight: 700;
  }
  .training_contact_section_button {
    font-size: 22px;
    width: 45%;
    margin-left: 28%;
    margin-top: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* TrainingAll */
  .trainingAll.common_image {
    margin: 20px auto;
  }
  /* Private Lessons */
  .privateLessons.common_image {
    background-position: 0%;
  }
  /* Board And Train */
  .boardAndTrain.common_image {
    background-position: 20% 60%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.common_image {
    background-position: 30%;
  }
  /* Day Training */
  .dayTraining.common_image {
    background-position: 20% 40%;
  }
  /* BT Adult */
  .btAdult.common_image {
    background-position: 0% 38%;
  }
  /* BT Puppy */
  .btPuppy.common_image {
    background-position: 0% 30%;
  }

  /* Contact Form */
  .training_form_col {
    background-color: white;
    width: inherit;
    padding-bottom: 0rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  .training_contact_us_form > div {
    margin: 2rem 2rem 2rem 1rem;
    width: 80%;
  }
  .training_contact_us_form > div > .form-label {
    font-size: 18px;
  }
  .training_contact_us_form > div > .form-control {
    padding: 0.5rem;
    font-size: 1rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* TrainingAll */
  /* Private Lessons */
  .privateLessons.common_image {
    background-position: 0 30%;
  }
  /* Board And Train */
  .boardAndTrain.common_image {
    background-position: 0% 65%;
  }
  /* Buy A Trained Puppy */
  .buyATrainedPuppy.common_image {
    background-position: 0% 0%;
  }
  /* Day Training */
  .dayTraining.common_image {
    background-position: 30% 50%;
  }
  /* BT Adult */
  .btAdult.common_image {
    background-position: 0% 40%;
  }
  /* BT Puppy */
  .btPuppy.common_image {
    background-position: 0% 20%;
  }

  /* Accordion */
  .training_accordion {
    width: 700px;
    margin: 0.5rem auto;
  }
  /* Contact Form */
  .training_form_col {
    width: 40%;
  }
  .training_contact_us_form > div {
    margin: 0 auto;
    width: 90%;
  }
}
