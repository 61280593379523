/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* Choosing */
  .beginsWithAConsult {
    background-image: url("../StockPhotos/beginsWithAConsult.jpg");
  }
  .findingTheBestBreed {
    background-image: url("../StockPhotos/findingTheBestBreed.jpg");
  }
  .choosingABreeder {
    background-image: url("../StockPhotos/choosingABreeder.jpg");
  }
  .testingInTheRealWorld {
    background-image: url("../StockPhotos/testingInTheRealWorld.jpg");
  }
  .goHomePackage {
    background-image: url("../StockPhotos/goHomePackage.jpg");
  }

  /* profiles */
  .profile.louise {
    background-image: url("../StockPhotos/Puppies/Louise/profile.jpeg");
  }
  .profile.dolly {
    background-image: url("../StockPhotos/Puppies/Dolly/profile.jpeg");
  }
  .profile.denver {
    background-image: url("../StockPhotos/Puppies/Denver/profile.jpeg");
  }
  .profile.mango {
    background-image: url("../StockPhotos/Puppies/Mango/profile.jpeg");
  }
  .profile.kiwi {
    background-image: url("../StockPhotos/Puppies/Kiwi/profile.jpg");
  }
  .profile.jolene {
    background-image: url("../StockPhotos/Puppies/Jolene/profile.jpeg");
  }
  .profile.rocky {
    transform: scale(1.2);

    background-position: 0% 90%;
    background-image: url("../StockPhotos/Puppies/Rocky/profile.jpeg");
  }
  .profile.tippy {
    background-image: url("../StockPhotos/Puppies/Tippy/profile.jpeg");
  }
  .profile.spencer {
    background-position: 0% 45%;
    background-image: url("../StockPhotos/Puppies/Spencer/profile.jpg");
  }
  .profile.klaus {
    background-image: url("../StockPhotos/Puppies/Klaus/profile.jpg");
  }
  .profile.penny {
    background-position: 40% 100%;
    background-image: url("../StockPhotos/Puppies/Penny/profile.jpg");
  }
  .profile.rowan {
    background-image: url("../StockPhotos/Puppies/Rowan/profile.jpeg");
  }
  .profile.kirby {
    transform: scale(1.3);

    background-position: 90% 90%;
    background-image: url("../StockPhotos/Puppies/Kirby/profile.jpeg");
  }
  .profile.omega {
    background-image: url("../StockPhotos/Puppies/Omega/profile.jpg");
  }
  .profile.grace {
    background-image: url("../StockPhotos/Puppies/Gracie_Pepper/profile.jpg");
  }
  .profile.ruckus {
    background-position: 0% 0%;
    background-image: url("../StockPhotos/Puppies/Ruckus/profile.jpeg");
  }
  .profile.josie {
    background-image: url("../StockPhotos/Puppies/Josie/profile.jpg");
  }
  .profile.fig {
    transform: rotate(270deg) scale(1.3);
    background-position: 0% 30%;
    background-image: url("../StockPhotos/Puppies/Fig/profile.jpg");
  }
  .profile.clover {
    background-image: url("../StockPhotos/Puppies/Clover/profile.jpg");
  }
  .profile.captain {
    background-position: 0% 5%;
    background-image: url("../StockPhotos/Puppies/Captain/profile.jpg");
  }
  .profile.fozzie {
    transform: scale(1.5);

    background-position: 0% 50%;
    background-image: url("../StockPhotos/Puppies/Fozzie/practicing_place.jpg");
  }
  .profile.sullivan {
    background-image: url("../StockPhotos/Puppies/Sullivan/profile.jpg");
  }
  /* .profile.gnocchi {
      background-image: url("../StockPhotos/Puppies/Gnocchi/profile.jpeg");
    }
    .profile.nugget {
      background-image: url("../StockPhotos/Puppies/Nugget/profile.jpeg");
    } */
  .profile.bella {
    background-position: 100% 35%;
    background-image: url("../StockPhotos/Puppies/Bella/profile.jpeg");
  }
  .profile.jasmine {
    background-image: url("../StockPhotos/Puppies/Jasmine/profile.jpeg");
  }
  .profile.opal {
    background-position: 40% 100%;
    background-image: url("../StockPhotos/Puppies/Opal/profile.jpeg");
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* Choosing */
  .beginsWithAConsult {
    background-image: url("../StockPhotos/beginsWithAConsult.jpg");
  }
  .findingTheBestBreed {
    background-image: url("../StockPhotos/findingTheBestBreed.jpg");
  }
  .choosingABreeder {
    background-image: url("../StockPhotos/choosingABreeder.jpg");
  }
  .testingInTheRealWorld {
    background-image: url("../StockPhotos/testingInTheRealWorld.jpg");
  }
  .goHomePackage {
    background-image: url("../StockPhotos/goHomePackage.jpg");
  }

  /* profiles */
  .profile.louise {
    background-image: url("../StockPhotos/Puppies/Louise/profile.jpeg");
  }
  .profile.dolly {
    background-image: url("../StockPhotos/Puppies/Dolly/profile.jpeg");
  }
  .profile.denver {
    background-image: url("../StockPhotos/Puppies/Denver/profile.jpeg");
  }
  .profile.mango {
    background-image: url("../StockPhotos/Puppies/Mango/profile.jpeg");
  }
  .profile.kiwi {
    background-image: url("../StockPhotos/Puppies/Kiwi/profile.jpg");
  }
  .profile.jolene {
    background-image: url("../StockPhotos/Puppies/Jolene/profile.jpeg");
  }
  .profile.rocky {
    background-image: url("../StockPhotos/Puppies/Rocky/profile.jpeg");
  }
  .profile.tippy {
    background-image: url("../StockPhotos/Puppies/Tippy/profile.jpeg");
  }
  .profile.spencer {
    background-position: 0% 45%;
    background-image: url("../StockPhotos/Puppies/Spencer/profile.jpg");
  }
  .profile.klaus {
    background-image: url("../StockPhotos/Puppies/Klaus/profile.jpg");
  }
  .profile.penny {
    background-position: 40% 100%;
    background-image: url("../StockPhotos/Puppies/Penny/profile.jpg");
  }
  .profile.rowan {
    background-image: url("../StockPhotos/Puppies/Rowan/profile.jpeg");
  }
  .profile.kirby {
    background-image: url("../StockPhotos/Puppies/Kirby/profile.jpeg");
  }
  .profile.omega {
    background-image: url("../StockPhotos/Puppies/Omega/profile.jpg");
  }
  .profile.grace {
    background-image: url("../StockPhotos/Puppies/Gracie_Pepper/profile.jpg");
  }
  .profile.ruckus {
    background-position: 40% 0%;
    background-image: url("../StockPhotos/Puppies/Ruckus/profile.jpeg");
  }
  .profile.josie {
    background-image: url("../StockPhotos/Puppies/Josie/profile.jpg");
  }
  .profile.fig {
    transform: rotate(270deg) scale(1.3);
    background-position: 0% 30%;
    background-image: url("../StockPhotos/Puppies/Fig/profile.jpg");
  }
  .profile.clover {
    background-image: url("../StockPhotos/Puppies/Clover/profile.jpg");
  }
  .profile.captain {
    background-position: 0% 5%;
    background-image: url("../StockPhotos/Puppies/Captain/profile.jpg");
  }
  .profile.fozzie {
    transform: scale(1.5);

    background-position: 0% 50%;
    background-image: url("../StockPhotos/Puppies/Fozzie/practicing_place.jpg");
  }
  .profile.sullivan {
    background-image: url("../StockPhotos/Puppies/Sullivan/profile.jpg");
  }
  /* .profile.gnocchi {
      background-image: url("../StockPhotos/Puppies/Gnocchi/profile.jpeg");
    }
    .profile.nugget {
      background-image: url("../StockPhotos/Puppies/Nugget/profile.jpeg");
    } */
  .profile.bella {
    background-position: 0% 35%;
    background-image: url("../StockPhotos/Puppies/Bella/profile.jpeg");
  }
  .profile.jasmine {
    background-image: url("../StockPhotos/Puppies/Jasmine/profile.jpeg");
  }
  .profile.opal {
    background-position: 40% 100%;
    background-image: url("../StockPhotos/Puppies/Opal/profile.jpeg");
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
