/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .common_container {
    z-index: 1;
    position: relative;
    top: -12rem;
    width: 100vw;
    padding: 0;
    margin-bottom: -10rem;
  }
  .common_header {
    width: 100vw;
    background-color: var(--sea-green-dark);
  }
  .common_header > a:first-child {
    margin: 0 0 0 5%;
    padding: 10px 0 10px 12px;
  }
  .common_header > a {
    color: var(--cedar-light);
    text-decoration: none;
  }
  .common_header > a:last-child {
    color: var(--almond-primary);
    font-weight: 500;
  }
  .common_card {
    width: 100%;
    border: none;
  }
  .common_card > * {
    margin: 0 5% 0 32px;
    padding: 0 !important;
  }
  .common_card > .card-title {
    text-transform: uppercase;
    margin: 12px 32px 12px 32px;
  }
  .common_image {
    margin: 1rem auto;
    padding: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
    height: 250px;
    display: block;
  }
  .show + .common_accordion_image {
    margin: 1rem auto;
    padding: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
    height: 250px;
    display: block;
  }
  .common_card > .figure-caption {
    font-size: 16px;
    font-family: "Satisfy", cursive;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .common_container {
    z-index: 1;
    position: relative;
    top: -10rem;
    padding: 0 0;
  }
  .common_container.margin_bottom {
    margin-bottom: -10rem;
  }
  .common_header {
    width: 100vw;
    background-color: var(--sea-green-dark);
  }
  .common_header > a:first-child {
    color: var(--cedar-light);
    margin: 0 0 0 32px;
    padding: 10px 0 10px 12px;
  }
  .common_header > a {
    color: var(--cedar-light);
    text-decoration: none;
  }
  .common_header > a:last-child {
    color: var(--almond-primary);
    font-weight: 500;
  }

  .common_card {
    width: 100%;
    border: none;
  }
  .common_card > * {
    margin: 0 32px 0 32px;
    padding: 10px 16px 10px 12px;
  }
  .common_card > .card-title {
    text-transform: uppercase;
    margin: 12px 32px 12px 32px;
  }
  .common_image {
    margin: 32px 32px 32px 46px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 85%;
    height: 350px;
    display: block;
  }
  .show + .common_accordion_image {
    margin: 1rem auto;
    padding: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
    height: 250px;
    display: block;
  }
  .common_card > .figure-caption {
    font-size: 18px;
    font-family: "Satisfy", cursive;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .common_container {
    top: -15rem;
  }
  .common_container.margin_bottom {
    margin-bottom: -15rem;
  }
  .common_card {
    width: 100%;
    border: none;
    border-radius: 0;
  }
  .common_card.border_right {
    border-right: 1px solid rgba(0, 0, 0, 0.35);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .common_header {
    font-size: 1.25rem;
  }
  .common_card > .card-title {
    font-size: 1.5rem;
  }
  .common_image {
    margin: 20px auto;
    background-repeat: no-repeat;
    background-size: cover;
    width: 60%;
    height: 450px;
    display: block;
  }
  .common_card > .figure-caption {
    max-width: 75%;
    margin: 0 auto;
  }
}
