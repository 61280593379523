/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* The Team */
  .theTeam.common_image {
    /* background-image: url("../StockPhotos/todosteam.jpg"); */
    background-position: 50% 30%;
    filter: brightness(140%);
    background-size: 200%;
  }
  /* Team (ind page) Justine */
  .teamjustine.common_image {
    /* background-image: url("../StockPhotos/todosteamJustine.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
    background-size: 200%;
  }
  /* Team (ind page) Kyra */
  .teamkyra.common_image {
    /* background-image: url("../StockPhotos/todosteamKyra.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
    background-size: 200%;
  }
  /* Team (ind page) Munch */
  .teammunch.common_image {
    /* background-image: url("../StockPhotos/todosteamMunch3.png"); */
    background-position: 0% 50%;
    filter: brightness(130%);
    background-size: 150%;
  }
  /* Team (ind page) Zeta */
  .teamzeta.common_image {
    /* background-image: url("../StockPhotos/todosteamZeta2.jpg"); */
    background-position: 60% 50%;
    filter: brightness(140%);
    background-size: 200%;
  }
  /* Team (ind page) Aurora */
  .teamaurora.common_image {
    /* background-image: url("../StockPhotos/todosteamAurora2.jpg"); */
    background-position: 50% 70%;
    filter: brightness(140%);
    background-size: 200%;
  }
  /* Team (ind page) Seeley */
  .teamseeley.common_image {
    /* background-image: url("../StockPhotos/down_with_sealy.jpg"); */
    background-position: 0% 50%;
    filter: brightness(140%);
  }
  .about_otherMembers_image {
    margin: 2rem auto 7rem auto;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-end;
  }
  .about_otherMembers_image > .figure-caption {
    font-size: 18px;
    font-family: "Satisfy", cursive;
    margin: 0 auto -7rem auto;
  }
  /* Kyra */
  .kyra.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamKyra.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
  }
  /* Munch */
  .munch.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamMunch2.png"); */
    background-position: 50% 100%;
    filter: brightness(110%);
  }
  /* Zeta */
  .zeta.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamZeta.jpg"); */
    background-position: 60% 50%;
    filter: brightness(140%);
  }
  /* Aurora */
  .aurora.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamAurora.jpg"); */
    background-position: 0% 80%;
    filter: brightness(140%);
  }
  /* Seeley */
  .seeley.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamSeeley.jpeg"); */
    background-position: 50% 50%;
    filter: brightness(140%);
  }
  /* Guests */
  .guests.about_otherMembers_image {
    /* background-image: url("../StockPhotos/walking_in_field.jpg"); */
    background-position: 50% 70%;
    background-size: 350%;
    filter: brightness(140%);
  }
  .about_otherMembers_card {
    width: 100%;
    border: none;
    margin: 1rem auto;
  }
  .about_otherMembers_card > * {
    padding: 10px 16px 10px 12px;
  }
  .about_otherMembers_card > .card-title {
    text-align: center;
    font-size: 28px;
  }
  .about_otherMembers_button {
    margin: 10px auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* The Team */
  .theTeam.common_image {
    /* background-image: url("../StockPhotos/todosteam.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
  }
  /* Team (ind page) Justine */
  .teamjustine.common_image {
    /* background-image: url("../StockPhotos/todosteamJustine.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
  }
  /* Team (ind page) Kyra */
  .teamkyra.common_image {
    /* background-image: url("../StockPhotos/todosteamKyra.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
  }
  /* Team (ind page) Munch */
  .teammunch.common_image {
    /* background-image: url("../StockPhotos/todosteamMunch3.png"); */
    background-position: 0% 60%;
    filter: brightness(130%);
  }
  /* Team (ind page) Zeta */
  .teamzeta.common_image {
    /* background-image: url("../StockPhotos/todosteamZeta2.jpg"); */
    background-position: 70% 50%;
    filter: brightness(140%);
  }
  /* Team (ind page) Aurora */
  .teamaurora.common_image {
    /* background-image: url("../StockPhotos/todosteamAurora2.jpg"); */
    background-position: 0% 80%;
    filter: brightness(140%);
  }
  /* Team (ind page) Seeley */
  .teamseeley.common_image {
    /* background-image: url("../StockPhotos/down_with_sealy.jpg"); */
    background-position: 0% 50%;
    filter: brightness(140%);
  }
  .about_otherMembers_image {
    margin: 32px 32px 7rem 46px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 85%;
    height: 400px;
    display: flex;
    align-items: flex-end;
  }
  .about_otherMembers_image > .figure-caption {
    font-size: 18px;
    font-family: "Satisfy", cursive;
    margin: 0 auto -7rem auto;
  }
  /* Kyra */
  .kyra.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamKyra.jpg"); */
    background-position: 40% 50%;
    filter: brightness(140%);
  }
  /* Munch */
  .munch.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamMunch2.png"); */
    background-position: 40% 50%;
    filter: brightness(120%);
  }
  /* Zeta */
  .zeta.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamZeta.jpg"); */
    background-position: 50% 50%;
    filter: brightness(140%);
  }
  /* Aurora */
  .aurora.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamAurora.jpg"); */
    background-position: 50% 50%;
    filter: brightness(140%);
  }
  /* Seeley */
  .seeley.about_otherMembers_image {
    /* background-image: url("../StockPhotos/todosteamSeeley.jpeg"); */
    background-position: 50% 50%;
    filter: brightness(140%);
  }
  /* Guests */
  .guests.about_otherMembers_image {
    /* background-image: url("../StockPhotos/walking_in_field.jpg"); */
    background-position: 50% 80%;
    background-size: 400%;
    filter: brightness(140%);
  }
  .floatLeft {
    display: flex;
  }
  .floatRight {
    display: flex;
    flex-direction: row-reverse;
  }
  .about_otherMembers_card {
    width: 100%;
    border: none;
    margin: 32px 32px 32px 46px;
  }
  .about_otherMembers_card > * {
    padding: 10px 16px 10px 12px;
  }
  .about_otherMembers_card > .card-title {
    text-align: center;
    font-size: 28px;
  }
  .about_otherMembers_button {
    margin: 10px auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* Kyra */
  .kyra.about_otherMembers_image {
    background-position: 50% 100%;
  }
  /* Munch */
  /* .munch.about_otherMembers_image {
    background-position: 40% 50%;
    filter: brightness(140%);
  } */
  /* Aurora */
  .aurora.about_otherMembers_image {
    background-position: 50% 100%;
  }
  .about_otherMembers_card > .card-title {
    font-size: 32px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* The Team */
  .theTeam.common_image {
    background-position: 40% 30%;
  }
  /* Kyra */
  .kyra.about_otherMembers_image {
    background-position: 40% 50%;
  }
  /* Munch */
  .munch.about_otherMembers_image {
    background-position: 50% 100%;
  }
  /* Zeta */
  .zeta.about_otherMembers_image {
    background-position: 100% 50%;
  }
  /* Aurora */
  .aurora.about_otherMembers_image {
    background-position: 0% 60%;
  }
}
