/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .puppy_home_container {
    padding: 0;
    width: calc(100vw + 5px);
    background-color: var(--bg-light);
  }
  .puppy_home_container .row {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    display: grid;
  }
  .puppy_home_container .row.titles {
    padding: 4% 0 0 0;
    background-color: var(--almond-dark);
  }
  .puppy_home_container .title {
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto;
    max-width: 80vw;
  }
  .puppy_home_container .title.two {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 1rem;
  }
  .puppy_home_container .view_puppies {
    padding: 0;
    width: 100%;
  }
  .puppy_home_container .view_puppies .col {
    width: 100%;
    height: 100vw;
  }
  .puppy_home_container .view_puppies .col:last-child {
    margin-bottom: 0;
  }
  .view_puppies .card_inner {
    border: none;
    font-family: "Poppins", sans-serif;
    background-color: transparent;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 0;
    height: 100%;
  }
  .view_puppies .puppy_image {
    background-position: 90% 45%;
    width: 100%;
    height: 100%;
    filter: opacity(0.9);
    background-size: cover;
  }
  .view_puppies .card_header {
    font-size: 32px;
    padding: 3% 0 3% 0;
    background-color: transparent;
    border-bottom: none;
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: var(--almond-light);
    font-size: 24px;
    padding: 0;
  }
  .view_puppies .header_div {
    text-align: left;
    margin-top: 0;
    bottom: 15px;
    position: absolute;
    transform: none;
    left: 0;
    padding: 30px 30px 0 30px;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
  }
  .view_puppies .col:hover .header_div {
    display: flex;
    justify-content: space-between;
  }
  .view_puppies .col .header_div .arrow_right {
    visibility: hidden;
  }
  .puppy_home_container .btn {
    width: auto;
    margin: 30px auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .puppy_home_container {
    padding: 0;
    width: calc(100vw + 3px);
    background-color: var(--bg-light);
  }
  .puppy_home_container .row {
    padding: 0;
    width: 100%;
    display: grid;
  }
  .puppy_home_container .row.titles {
    padding: 4% 0 0 0;
    background-color: var(--almond-dark);
  }
  .puppy_home_container .title {
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto;
    max-width: 80vw;
  }
  .puppy_home_container .title.two {
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 1rem;
  }
  .puppy_home_container .view_puppies {
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .puppy_home_container .view_puppies .col {
    width: 50vw;
    height: 50vw;
  }
  .puppy_home_container .view_puppies .col:last-child {
    margin: 0 auto;
  }
  .view_puppies .card_inner {
    border: none;
    font-family: "Poppins", sans-serif;
    background-color: transparent;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 0;
    height: 100%;
  }
  .puppy_home_container .view_puppies .col .puppy_image {
    -webkit-transition: 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);
    transition: 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);
  }
  .puppy_home_container .view_puppies .col:hover .puppy_image {
    -webkit-transform: scale(1.11);
    -webkit-transition: 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);
    transform: scale(1.11);
    transition: 0.62s cubic-bezier(0.05, 0.2, 0.1, 1);
  }
  .view_puppies .puppy_image {
    background-position: 90% 45%;
    width: 100%;
    height: 100%;
    filter: opacity(0.9);
    background-size: cover;
  }
  .view_puppies .header_div {
    text-align: left;
    margin-top: 0;
    bottom: 15px;
    position: absolute;
    transform: none;
    left: 0;
    padding: 30px 30px 0 30px;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
  }
  .view_puppies .col:hover .header_div {
    display: flex;
    justify-content: space-between;
  }
  .view_puppies .header_div .card_header {
    background-color: transparent;
    border-bottom: none;
    color: var(--almond-light);
    font-size: 24px;
    padding: 0;
  }
  .view_puppies .col .header_div .arrow_right {
    visibility: hidden;
  }
  .view_puppies .col:hover .header_div .arrow_right {
    visibility: visible;
    width: 10%;
    fill: var(--almond-light);
  }
  .puppy_home_container .btn {
    width: auto;
    margin: 30px auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .puppy_home_container .view_puppies .col {
    width: 33.2vw;
    height: 33.2vw;
  }

  .puppy_home_container .view_puppies .col:last-child {
    margin: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .first_row .services_offered.card_button {
    margin-top: -10% !important;
    margin-bottom: -7% !important;
    display: inline-block;
  }
  .puppy_image.weDeliverYourPuppy {
    background-position: 0% 57%;
  }
  .services_offered .title {
    font-size: 44px;
  }
  .second_row .view_puppies .col:last-child {
    margin-bottom: 80px;
  }
  .second_row .view_puppies .col {
    margin: 0 auto;
    height: 230px;
  }
}
