/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .slider_container {
    height: 50vh;
    width: 100%;
    position: relative;
    margin: 25% auto;
    overflow: hidden;
  }
  .active {
    display: inline-block;
  }
  .inactive {
    display: none;
  }
  .slides {
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .slide_image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .arrows {
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20%;
    padding: 1rem;
    margin-top: -3rem;
  }
  .prev,
  .next {
    cursor: pointer;
  }
  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  .transparent.btn .chevron-left,
  .transparent.btn .chevron-right {
    height: 100%;
    fill: var(--almond-dark);
    visibility: visible;
  }
  .all_dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 101;
  }
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
  }
  .active_dot,
  .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .slider_container {
    height: 50vh;
    width: 80vw;
    max-width: 800px;
    max-height: 800px;
    position: relative;
    margin: 10% auto;
    overflow: hidden;
  }
  .active {
    display: inline-block;
  }
  .inactive {
    display: none;
  }
  .slides {
    height: 50vh;
    width: 100%;
    position: relative;
  }
  .slide_image {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .arrows {
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20%;
    padding: 1rem;
    margin-top: -3rem;
  }
  .prev,
  .next {
    cursor: pointer;
  }
  .prev:hover,
  .next:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease-in;
  }
  .transparent.btn .chevron-left,
  .transparent.btn .chevron-right {
    height: 100%;
    fill: var(--almond-dark);
    visibility: visible;
  }
  .all_dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 85%;
    justify-content: center;
    z-index: 101;
  }
  .dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
  }
  .active_dot,
  .dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
