/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .google_reviews_container {
    padding: 30% 0;
    width: 100vw;
    background-color: var(--almond-primary);
  }
  .google_reviews.card_inner {
    border: 2px solid var(--cedar-light);
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    max-width: 95vw;
    height: auto;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }
  .google_reviews.card_header {
    font-size: 32px;
    padding: 8% 0 10% 0;
    background-color: var(--cedar-primary);
    color: white;
  }
  .google_reviews_title {
    font-size: 32px;
    padding: 2% 0;
    text-align: center;
    text-transform: uppercase;
  }
  .google_reviews.card_body {
    padding: 1rem;
  }
  .google_reviews.column_image {
    width: 100%;
    height: 100px;
    margin-bottom: 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .google_reviews_container {
    margin-top: -2rem;
    height: 700px;
  }
  .google_reviews_container > .row {
    width: 100vw;
    height: inherit;
    margin-top: -2rem;
    position: relative;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
  }

  .google_reviews.bg_div {
    /* background-image: url("../StockPhotos/eye_contact.jpeg"); */
    background-repeat: no-repeat;
    background-position-x: 30%;
    background-position-y: 0%;
    position: absolute;
    height: 100%;
    width: 100vw;
    margin-top: -2rem;
  }

  .google_reviews.card_inner {
    float: right;
    width: 60%;
    min-height: 75%;
    margin-bottom: 2.5rem;
    padding-bottom: 1.5rem;
    /* -webkit-animation: google-reviews 15.5s infinite ease-out;
    animation: google-reviews 15.5s infinite ease-out; */
  }
  /* @-webkit-keyframes google-reviews {
    0% {
      left: 0px;
    }
    50% {
      left: 100%;
    }
    90% {
      left: 100%;
    }
  }
  @keyframes google-reviews {
    0% {
      left: 0px;
    }
    50% {
      left: 0px;
    }
    90% {
      left: 100%;
    }
  } */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .google_reviews.card_inner {
    width: 45%;
    margin-right: 1rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .google_reviews_container {
    height: 900px;
  }
  .google_reviews.bg_div {
    background-size: cover;
  }
  .google_reviews.card_inner {
    width: 35%;
    min-height: 75%;
    margin: 0 10%;
  }
}
