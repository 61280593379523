@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&family=Satisfy&display=swap");

:root {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  --almond-primary: #e5d4c0;
  --almond-light: #fffff3;
  --almond-dark: #b3a390;
  --cedar-primary: #c16552;
  --cedar-light: #f7947f;
  --cedar-dark: #8d3829;
  --sea-green-primary: #2ca49c;
  --sea-green-light: #67d6cd;
  --sea-green-dark: #00756e;
  --bg-light: #fefefe;
  --bg-off-light: #f8f8f8;

  --text-color-light: #405163;
  background-color: var(--almond-primary);
}

.btn {
  color: black;
  box-shadow: 0px 18px 24px -6px rgb(0 0 0 / 30%);
  padding: 12px;
}
.btn.almond {
  background-color: var(--almond-primary);
  border-color: var(--almond-light);
}
.btn.cedar {
  background-color: var(--cedar-primary);
  border-color: var(--cedar-light);
  color: white;
}
.btn.transparent {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  transform: scale(1);
}
.transparent.btn-primary:hover,
.transparent.btn-primary:focus,
.transparent.btn-primary:focus-visible,
.transparent.btn-primary:active,
.transparent.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  transform: scale(1.3);
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline-color: transparent;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: var(--sea-green-dark);
  border-color: var(--sea-green-primary);
}

.dropdown-menu-dark .dropdown-item:active {
  background-color: var(--cedar-primary) !important;
}

a {
  color: var(--text-color-light);
  /* text-decoration: none; */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 374px) {
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -3.75vw !important;
  }
}

/* Min width means ABOVE
Max width means BELOW
*/
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 474px) and (min-width: 375px) {
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -3.25vw !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (min-width: 475px) {
  .no_show_elm {
    position: relative;
    width: 0;
    height: 0%;
  }
  .row {
    margin: 0 auto;
  }
  .small_container {
    margin-left: -2.75vw;
    border: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .no_show_elm {
    position: relative;
    width: 0;
    height: 0%;
  }
  .row {
    margin: 0 auto;
  }
  .small_container {
    max-width: 100vw;
  }
  .content-page > .main_container {
    padding: 0%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
