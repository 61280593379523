/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .navbar_footer {
    background-color: var(--sea-green-dark) !important;
    z-index: 10001;
    margin-top: -1%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbar_footer {
    background-color: var(--sea-green-dark) !important;
    z-index: 10001;
    position: relative !important;
    justify-content: center;
    margin-top: -1%;
  }
  .navbar_footer > .nav-link {
    padding: 0.5rem 3.5rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navbar_footer > .nav-link {
    padding: 0.5rem 1rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .navbar_footer {
    margin-top: 0;
  }
}
