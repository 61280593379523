/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home_services_offered_container {
    padding: 4% 0 0 0;
    width: 100vw;
    background-color: var(--almond-primary);
  }
  .first_row .home_services_offered_image {
    background-position: 80% 45%;
    min-width: 40vw;
    max-width: 60vw;
    min-height: 300px;
    margin: 0 auto;
    filter: opacity(0.9);
    background-size: cover;
  }
  .home_services_offered_image.youChooseAPuppy {
    background-image: url("../../Assets/StockPhotos/youChooseAPuppy.jpg");
  }
  .home_services_offered_image.weTrainYourPuppy {
    background-image: url("../../Assets/StockPhotos/weTrainYourPuppy.jpg");
  }
  .home_services_offered_image.weDeliverYourPuppy {
    background-position: 0% 45%;
    background-image: url("../../Assets/StockPhotos/weDeliverYourPuppy.jpg");
  }
  .services_offered.card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: inherit;
    margin-bottom: 1.5rem;
    background-color: transparent;
  }
  .services_offered.card_header {
    font-size: 32px;
    padding: 3% 0 3% 0;
    background-color: transparent;
    border-bottom: none;
  }
  .services_offered.card_body {
    padding: 1rem;
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    padding-bottom: 3rem;
  }
  .services_offered.card_button {
    margin-top: -15% !important;
    margin-bottom: -9% !important;
    display: inline-block;
  }

  .services_offered.second_row {
    background-color: var(--bg-off-light);
    position: relative;
    margin: 1.5rem auto 0 auto;
    padding: 4rem 0.5rem 0 0.5rem;
  }
  .second_row .view_puppies {
    padding-top: 0px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    float: none;
    margin-left: -1%;
    margin-right: -1%;
    margin-bottom: 24px;
  }
  .second_row .view_puppies .col {
    padding: 15px 1%;
  }
  .second_row .view_puppies .col:last-child {
    margin-bottom: 0;
  }

  .services_offered .title {
    text-align: center;
    font-size: 28px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto 4rem auto;
    max-width: 80vw;
  }
  .second_row .services_offered.card_inner {
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 0;
  }
  .second_row .card_header {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: var(--almond-light);
    font-size: 24px;
    padding: 0;
  }
  .second_row .home_services_offered_image {
    background-position: 90% 45%;
    width: 90vw;
    height: 90vw;
    margin: 0 auto;
    filter: opacity(0.9);
    background-size: cover;
  }
  .second_row .btn {
    width: auto;
    margin: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home_services_offered_container {
    padding: 4% 0 0 0;
    width: 100vw;
    background-color: var(--almond-primary);
  }
  .first_row .home_services_offered_image {
    background-position: 90% 45%;
    min-width: 50vw;
    max-width: 70vw;
    min-height: 400px;
    margin: 0 auto;
    filter: opacity(0.9);
    background-size: cover;
  }
  .home_services_offered_image.youChooseAPuppy {
    background-image: url("../../Assets/StockPhotos/youChooseAPuppy.jpg");
  }
  .home_services_offered_image.weTrainYourPuppy {
    background-image: url("../../Assets/StockPhotos/weTrainYourPuppy.jpg");
  }
  .home_services_offered_image.weDeliverYourPuppy {
    background-position: 0% 45%;
    background-image: url("../../Assets/StockPhotos/weDeliverYourPuppy.jpg");
  }
  .services_offered.card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: 80%;
    margin-bottom: 2rem;
    background-color: transparent;
  }
  .services_offered.card_header {
    font-size: 36px;
    padding: 3% 0 5% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-bottom: none;
  }
  .services_offered.card_body {
    padding: 1rem;
    border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
    padding-bottom: 3rem;
  }
  .services_offered.card_button {
    margin-top: -12% !important;
    margin-bottom: -7% !important;
    display: inline-block;
  }

  .services_offered.second_row {
    background-color: var(--bg-off-light);
    width: 100vw;
    margin: 4rem auto 0 auto;
    padding: 6rem 2rem;
  }

  .services_offered .title {
    text-align: center;
    font-size: 32px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto 4rem auto;
  }
  .second_row .view_puppies {
    margin: 0 -1%;
    padding-left: 1%;
    padding-right: 1%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .second_row .view_puppies .col:last-child {
    margin-bottom: 50px;
  }
  .second_row .view_puppies .col {
    margin: 0 auto;
    padding: 15px 1%;
    height: 420px;
  }
  .second_row .view_puppies .card_inner {
    flex-grow: 1;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }

  .second_row .card_header {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: var(--almond-light);
    font-size: 24px;
    padding: 0;
  }
  .second_row .home_services_offered_image {
    background-position: 90% 45%;
    max-width: 100%;
    height: 100%;
    filter: opacity(0.9);
    background-size: cover;
  }
  .second_row .btn {
    width: auto;
    margin: auto;
  }
  .third_row:last-child {
    padding-bottom: 0;
  }
  .third_row .blog_recent {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .third_row .blog_recent .col {
    margin-right: 0;
    float: none;
    margin-bottom: 40px;
  }

  .third_row .blog_recent .col:nth-child(2),
  .third_row .blog_recent .col:nth-child(3) {
    align-self: flex-start;
  }

  .third_row .blog_recent .col:first-child {
    width: 100%;
    margin-left: 0;
  }
  .third_row .blog_recent .col:not(:first-child) {
    width: 48%;
    margin-right: 4%;
  }
  .third_row .blog_recent .col:nth-child(2n + 3) {
    margin-right: 0 !important;
  }
  .third_row .card_inner {
    width: 100%;
  }
  .third_row .card_inner.small {
    display: block;
  }

  .third_row .home_services_offered_image {
    background-position: 90% 45%;
    width: inherit;
    height: 35vw;
    margin: 0 auto;
    filter: opacity(0.9);
    background-size: cover;
  }
  .small {
    transform: none;
    top: 0;
    display: inline-block;
    vertical-align: middle;
  }
  .third_row .home_services_offered_image.small {
    width: 80px;
    height: 80px;
    margin-right: 30px;
    line-height: 0;
    position: relative;
  }
  .home_services_offered_image.blackDoodle {
    background-position: 20% 0%;
    background-image: url("../StockPhotos/leaves.jpeg");
  }
  .home_services_offered_image.grace {
    background-position: 0% 5%;
    background-image: url("../../Assets/StockPhotos/porch.jpeg");
  }
  .third_row .parent_body.small {
    padding: 0;
    padding-right: 30px;
    margin-bottom: 0;
    vertical-align: middle;
    width: calc(100% - 120px);
  }
  .third_row .card_header {
    color: var(--almond-light);
    font-size: 24px;
  }
  .third_row .card_header.small {
    font-size: 16px;
    line-height: 25px;
  }
  .third_row .services_offered.card_body {
    width: 100%;
    border-bottom: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .second_row .view_puppies {
    justify-content: center;
  }
  .second_row .view_puppies .col {
    margin: 0 10px;
    height: 285px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .first_row .services_offered.card_button {
    margin-top: -10% !important;
    margin-bottom: -7% !important;
    display: inline-block;
  }
  .home_services_offered_image.weDeliverYourPuppy {
    background-position: 0% 57%;
  }
  .services_offered .title {
    font-size: 44px;
  }
  .second_row .view_puppies .col:last-child {
    margin-bottom: 80px;
  }
  .second_row .view_puppies .col {
    margin: 0 auto;
    height: 230px;
  }
}
