/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .interested_container {
    margin: 4rem auto;
    padding: 8% 0 0 0;
    width: 100vw;
    background-color: var(--bg-light);
  }
  .interested_container .card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: 80%;
    background-color: transparent;
    margin: 0 auto;
  }

  .interested_container .title {
    text-align: center;
    font-size: 32px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto 4rem auto;
  }
  .interested_container .card_button {
    display: inline-block;
    width: auto;
    margin: 0 auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .interested_container {
    padding: 4rem 2rem;
    width: 100vw;
    background-color: var(--bg-light);
  }
  .interested_container .card_inner {
    border: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
    color: #343a40 !important;
    width: 80%;
    background-color: transparent;
    margin: 0 auto;
  }

  .interested_container .title {
    text-align: center;
    font-size: 32px;
    padding: 2% 0;
    font-weight: 300;
    margin: 0rem auto 4rem auto;
  }
  .interested_container .card_button {
    display: inline-block;
    width: auto;
    margin: 0 auto;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
