/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .not_found.common_image {
    top: 8rem;
    width: 100%;
    height: 50vh;
    position: relative;
    z-index: 1;
    background-image: url("../StockPhotos/not_found.jpg");
    background-position: 50% 30%;
    background-size: 150%;
  }
  .not_found.common_card > * {
    padding: 10px 16px 10px 12px;
    z-index: 2;
  }
  .not_found.common_card > .card-title {
    text-align: center;
    font-size: 28px;
  }
  .not_found_button {
    margin: 2rem auto;
    padding: 2px 10px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .not_found.common_card {
    align-items: center;
  }
  .not_found.common_image {
    top: 8rem;
    width: 90%;
    height: 300px;
    position: relative;
    z-index: 1;
    background-image: url("../StockPhotos/not_found.jpg");
    background-position: 50% 100%;
  }
  .not_found.common_card > * {
    padding: 10px 16px 10px 12px;
    z-index: 2;
  }
  .not_found.common_card > .card-title {
    margin-top: 6rem;
    text-align: center;
    font-size: 28px;
  }
  .not_found.common_card > .card-body {
    max-width: 500px;
  }
  .not_found_button {
    margin: 2rem auto;
    padding: 5px 15px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .not_found.common_image {
    top: 14rem;
  }
  .not_found.common_card > .card-title {
    margin-top: 12rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .not_found.common_image {
    top: 14rem;
  }
  .not_found.common_card > .card-title {
    margin-top: 14rem;
  }
}
